import AirDatepicker from 'air-datepicker';
import localeHu from 'air-datepicker/locale/hu';
import moment from "moment/moment";
import 'moment/locale/hu';

const openHours = {
    3: [14, 18],
    5: [9, 18],
    6: [9, 18],
}

export function initReservationPopup() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const reservationPopupLinks = document.querySelectorAll('[href="#reservation-popup"]');
    if (reservationPopupLinks.length) {
        moment.locale('hu');
        for (const reservationPopupLink of reservationPopupLinks) {
            reservationPopupLink.addEventListener('click', e => {
                e.preventDefault();
                showReservationPopup();
            });
        }
        const popup = document.getElementById('reservation-popup');
        const closeButton = popup.querySelector('a.close');
        if (closeButton) {
            closeButton.addEventListener('click', e => {
                e.preventDefault();
                closeReservationPopup();
            });
        }
    }
}

function showReservationPopup() {
    const overlayContainer = document.querySelector('.reservation-popup-overlay');
    const popup = document.getElementById('reservation-popup');
    
    initReservationCalendar(popup);
    
    overlayContainer.classList.remove('hidden');
    popup.classList.remove('hidden');
    document.body.classList.add('overflow-hidden');
    setTimeout(() => {
        overlayContainer.classList.remove('opacity-0');
    }, 10);
}

function closeReservationPopup() {
    const overlayContainer = document.querySelector('.reservation-popup-overlay');
    const popup = document.getElementById('reservation-popup');
    overlayContainer.classList.add('hidden', 'opacity-0');
    popup.classList.add('hidden');
    document.body.classList.remove('overflow-hidden');
}

function initReservationCalendar(popup) {
    if (! popup || popup.dataset.init) {
        return;
    }
    popup.dataset.init = '1';
    const calendarWrapper = popup.querySelector('.calendar-wrapper');
    if (! calendarWrapper) {
        return;
    }
    const selectedMonth = popup.querySelector('.selected-month');
    const selectedDay = popup.querySelector('.selected-day');
    const timeListElements = popup.querySelectorAll('.time-options-wrapper a');
    const dateInput = popup.querySelector('input[name="fields[reservationTime][datetime]"]');
    const dateVisibleInput = popup.querySelector('input[name="fields[reservationTime][datetime]"] + input');
    const airDatePicker = new AirDatepicker(calendarWrapper, {
        locale: localeHu,
        inline: true,
        dateFormat: 'yyyy-MM-dd',
        minDate: new Date(),
        onChangeViewDate: ({month, year}) => {
            selectedMonth.innerText = moment(new Date(year, month)).format('YYYY. MMMM');
            for (let d = new Date(year, month, 1); d <= new Date(year, month + 1, 0); d.setDate(d.getDate() + 1)) {
                if (! dateCanBeSelected(d)) {
                    airDatePicker.disableDate(d);
                }
            }
        },
        onBeforeSelect: ({date}) => dateCanBeSelected(date),
        onSelect: ({date}) => {
            if (! date) {
                selectedDay.innerText = '';
                for (const timeListElement of timeListElements) {
                    timeListElement.classList.add('hidden');
                }
            } else {
                selectedDay.innerText = moment(date).format('MMMM D., dddd');
                const dayOfWeek = date.getDay();
                const selectedOpenHours = openHours[dayOfWeek].map(o => new Date(1, 1, 1, o, 0));
                for (const timeListElement of timeListElements) {
                    const t = timeListElement.dataset.time.split(':');
                    const dt = new Date(1, 1, 1, parseInt(t[0]), parseInt(t[1]));
                    timeListElement.classList.toggle('hidden', !(selectedOpenHours[0] <= dt && dt < selectedOpenHours[1]));
                    timeListElement.classList.add('hover:bg-black/10');
                    timeListElement.classList.remove('bg-black', 'text-yellow');
                    dateInput.value = '';
                }
            }
        },
    });
    airDatePicker.prev();
    let availableDay = null;
    do {
        airDatePicker.next();
        availableDay = calendarWrapper.querySelector('.air-datepicker-body--cells > div:not(.-other-month-):not(.-disabled-)');
    } while (availableDay === null);
    popup.querySelector('.arrows .prev-month').addEventListener('click', e => {
        e.preventDefault();
        airDatePicker.prev();
    });
    popup.querySelector('.arrows .next-month').addEventListener('click', e => {
        e.preventDefault();
        airDatePicker.next();
    });

    for (const timeListElement of timeListElements) {
        timeListElement.addEventListener('click', e => {
            e.preventDefault();
            for (const tle of timeListElements) {
                tle.classList.add('hover:bg-black/10');
                tle.classList.remove('bg-black', 'text-yellow');
            }
            e.currentTarget.classList.add('bg-black', 'text-yellow');
            e.currentTarget.classList.remove('hover:bg-black/10');
            if (airDatePicker.selectedDates.length === 1) {
                const sd = airDatePicker.selectedDates[0];
                const d = new Date(sd.getFullYear(), sd.getMonth(), sd.getDate());
                const st = e.currentTarget.dataset.time.split(':');
                d.setHours(parseInt(st[0]));
                d.setMinutes(parseInt(st[1]));
                dateInput.value = moment(d).format('yyyy-MM-DD HH:mm');
                dateVisibleInput.value = moment(d).format('yyyy-MM-DD HH:mm');
            }
        });
    }
    
}
function dateCanBeSelected(date) {
    const dayOfWeek = date.getDay();
    /* TODO remove this after 2024-05-11: */
    return openHours.hasOwnProperty(dayOfWeek) && date > new Date(2024, 4, 10);
}